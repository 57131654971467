<template>
  <cw-page
    icon="cancel"
    hero-image="application-error.jpg"
    class="link-expired"
  >
    <v-row>
      <v-col>
        <p>
          <translate>
            The loan offer is valid for 30 days from the completion of the application.
            Unfortunately, the deadline has expired, so our loan offer is no longer valid.
            To get a new offer, please fill out the loan application again. You can access the
            loan application through our website.
          </translate>
        </p>
      </v-col>
    </v-row>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="link-expired__button"
        color="primary"
        href="https://www.saldo.com"
        @click="$eventLogger.clickEvent($event)"
      >
        <translate>
          Apply for a loan from Saldo
        </translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
export default {
  name: 'CwLinkExpired',
};
</script>

<style lang="scss">
.link-expired {
  &__content {
    max-width: 600px;
  }
}
</style>
